import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import frFR from 'antd/lib/locale/fr_FR';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { useBackofficeAuthenticateMutation } from '@invivo-capital/accessors';
import { AuthProvider, AuthTemplate } from '@invivo-capital/components';
import { GlobalStyle, SplashScreen, defaultTheme } from '@invivo-capital/ui-kit';

import { store } from './slices/store';

const AppRoutes = React.lazy(() => import('./pages'));

export const App: React.FC = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <ConfigProvider locale={frFR}>
                <GlobalStyle />
                <AuthProvider>
                    <Provider store={store}>
                        <Router>
                            <AuthTemplate useAuthenticateMutation={useBackofficeAuthenticateMutation}>
                                <React.Suspense fallback={<SplashScreen.Loading tip="Chargement..." />}>
                                    <AppRoutes />
                                </React.Suspense>
                            </AuthTemplate>
                            <ToastContainer
                                autoClose={false}
                                draggable={false}
                                toastStyle={{ wordBreak: 'break-all' }}
                            />
                        </Router>
                    </Provider>
                </AuthProvider>
            </ConfigProvider>
        </ThemeProvider>
    );
};
