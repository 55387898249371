import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'modules/app';
import { suppressFindDOMNodeError } from 'modules/common/utils/errors';

import { reportWebVitals } from './reportWebVitals';

dayjs.extend(utc);

const isDevEnvironment = process.env.NODE_ENV === 'development';

if (isDevEnvironment) {
    // Uncomment to enable mocks. Mock server breaks uploader progress.
    // import('modules/common/utils/mirage').then(({ makeServer }) => makeServer());
    suppressFindDOMNodeError();
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals(console.debug);
