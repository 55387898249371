import { configureStore } from '@reduxjs/toolkit';

import {
    accountingAccountsApi,
    commonApi,
    declarationApi,
    fileApi,
    membersApi,
    membersContactsApi,
    outgoingEventsApi,
    publiPostageApi,
    rtkQueryErrorLogger,
    simulateCalendarApi,
    transactionsApi,
} from '@invivo-capital/accessors';

export const store = configureStore({
    reducer: {
        [declarationApi.reducerPath]: declarationApi.reducer,
        [accountingAccountsApi.reducerPath]: accountingAccountsApi.reducer,
        [membersApi.reducerPath]: membersApi.reducer,
        [transactionsApi.reducerPath]: transactionsApi.reducer,
        [accountingAccountsApi.reducerPath]: accountingAccountsApi.reducer,
        [outgoingEventsApi.reducerPath]: outgoingEventsApi.reducer,
        [membersContactsApi.reducerPath]: membersContactsApi.reducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [simulateCalendarApi.reducerPath]: simulateCalendarApi.reducer,
        [publiPostageApi.reducerPath]: publiPostageApi.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        declarationApi.middleware,
        membersApi.middleware,
        membersContactsApi.middleware,
        commonApi.middleware,
        transactionsApi.middleware,
        accountingAccountsApi.middleware,
        outgoingEventsApi.middleware,
        fileApi.middleware,
        simulateCalendarApi.middleware,
        publiPostageApi.middleware,
        rtkQueryErrorLogger,
    ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
